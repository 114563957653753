<template>
  <div>
    <div v-if="!isMobile()">
      <LessonsDesktop />
    </div>
    <div v-else>
      <LessonsMobile />
    </div>
  </div>
</template>

<script>
import LessonsDesktop from './LessonsDesktop.vue'
import LessonsMobile from './LessonsMobile.vue'

export default {
  components: {
    LessonsDesktop,
    LessonsMobile,
  },

  data() {
    return {
    }
  },
  methods: {
    isMobile() {
      if (document.documentElement.clientWidth <= 800) {
        return true
      }
      return false
    },
  },
}
</script>
