<template>
  <div>
    <b-card
      no-body
    >
      <b-tabs
        card
        class="p-0"
        pills
      >
        <b-tab
          title="Active"
          active
          class="p-0"
        >
          <b-card-actions
            no-actions
            no-body
            title="Tickets"
            class="p-0"
          >
            <div>
              <b-overlay
                :show="loadingLessons"
                rounded="sm"
              >
                <b-table
                  id="tickets-table"
                  hover
                  class="table_tickets"
                  responsive="sm"
                  selectable
                  select-mode="single"
                  :items="active_tickets"
                  :fields="fields_tickets_mobile"
                  :per-page="perPage"
                  :current-page="currentPage"
                  @row-clicked="lessonsRefresh"
                >
                  <template #cell(create_date)="row">
                    {{ moment(row.item.create_date).format('MMM Do YYYY') }}
                  </template>
                </b-table>
              </b-overlay>
            </div>
            <b-pagination
              v-model="currentPage"
              align="center"
              :total-rows="rows_tickets"
              :per-page="perPage"
              aria-controls="tickets-table"
            />
          </b-card-actions>
        </b-tab>
        <b-tab
          title="Past"
          class="p-0"
        >
          <b-card-actions
            no-actions
            no-body
            title="Tickets"
          >
            <b-overlay
              :show="loadingLessons"
              rounded="sm"
            >
              <b-table
                hover
                class="table_lessons"
                responsive="sm"
                :items="past_tickets"
                :fields="fields_tickets"
                @row-clicked="lessonsRefresh"
              >
                <template #cell(create_date)="row">
                  {{ moment(row.item.create_date).format('MMM Do YYYY') }}
                </template>
              </b-table>
            </b-overlay>
          </b-card-actions>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-card-actions
      no-actions
      no-body
      title="Lessons"
    >
      <b-row class="ml-1">
        <b-col>
          Ticket: {{ selectedTicket.number }}
        </b-col>
      </b-row>
      <b-row class="ml-1 pb-1">
        <b-col>
          Client: {{ selectedTicket.client_name }}
        </b-col>
      </b-row>
      <div>
        <b-overlay
          :show="loadingLessons"
          rounded="sm"
        >
          <b-table
            class="table_lessons"
            responsive="sm"
            :items="lessons"
            :fields="fields_lessons_mobile"
            @row-clicked="item=>$set(item, '_showDetails', !item._showDetails)"
          >
            <template v-slot:cell(selected)="row">
              <b-form-checkbox
                v-if="row.item.fact_date_time && !row.item.payment_id"
                v-model="row.item.selected"
                type="checkbox"
                switch
              />
            </template>
            <template #cell(plan_date_time)="row">
              {{ moment(row.item.plan_date_time).format('MMM Do YYYY, h:mm a') }}
            </template>
            <template #cell(move_date_time)="row">
              {{ moment(row.item.move_date_time).format('MMM Do YYYY, h:mm a') }}
            </template>

            <template #cell(fact_date_time)="row">
              {{ row.item.fact_date_time ? moment(row.item.fact_date_time).format('MMM Do YYYY') : '' }}
            </template>

            <template #cell(bt_confirm_payment)="row">
              <b-button
                v-if="!row.item.fact_date_time"
                v-b-modal.modal-center
                size="sm"
                class="sm-2"
                variant="primary"
                @click="show_confirm_lesson_modal(row.item)"
              >
                {{ 'Confirm' }}
              </b-button>
            </template>
          </b-table>
        </b-overlay>
      </div>
      <!--sdsd-->
      <b-modal
        id="modal-center"
        ref="myModal"
        display="flex"
        centered
        title="Confirm lesson"
        hide-footer
      >
        <h4>1. Choose actual date your lesson</h4>
        <b-card>
          <div class="form-floating">
            <label>Actual Date</label>
            <div>
              <b-form-datepicker
                id="fact_date"
                v-model="modal_item.fact_date_time"
                locale="en"
                class="form-control"
                placeholder="Input fact date"
              />
              <b-form-input
                id="fact_time"
                v-model="modal_item.fact_time"
                pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                type="time"
                class="form-control"
                placeholder="Input fact time"
              />
            </div>
          </div>
        </b-card>
        <div id="demo">
          <b-link
            class="mt-1"
            @click="show = !show"
          >
            Upload Photo Proof (?)
          </b-link>
          <transition name="slide-fade">
            <p v-if="!show">
              Upload a photo of you with your student at this lesson to expedite your payment.  Selfie or photo together
            </p>
          </transition>
        </div>
        <b-media no-body>
          <b-media-body class="mt-50">
            <!-- upload button -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sl"
              class="mb-75 mr-75"
              @click="$refs.refInputEl.$el.click()"
            >
              Upload
            </b-button>
            <b-form-file
              ref="refInputEl"
              v-model="profileFile"
              accept=".jpg, .png, .gif"
              :hidden="true"
              plain
              @change="handleImage"
            />
          </b-media-body>
        </b-media>
        <h4>2. Are you sure you want to confirm the lesson</h4>
        <b-row>
          <b-col>
            <b-button
              :disabled="!this.modal_item.fact_date_time"
              class="mt-1"
              block
              variant="primary"
              @click="confirm_lesson"
            >
              Yes
            </b-button>
          </b-col>
          <b-col>
            <b-button
              class="mt-1"
              variant="danger"
              block
              @click="hideModal"
            >
              NO
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-alert
              v-if="!this.modal_item.fact_date_time"
              show
              variant="danger"
              class="mt-2 p-1"
            >
              Select the actual date of the lesson
            </b-alert>
          </b-col>
        </b-row>
      </b-modal>
    </b-card-actions>
    <div class="payment_modal">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="showModalPayment"
      >
        <b-icon icon="wallet2" />
        Payment
      </b-button>
      <b-modal
        ref="paymentModal"
        hide-footer
        display="flex"
        centered
        :title="selectedTicket ? 'Client: ' + selectedTicket.client_name : ''"
      >
        <h3>Selected lessons</h3>
        <b-table
          responsive="sm"
          :items="selected_lessons"
          :fields="fields_payments"
        >
          <template #cell(fact_date_time)="row">
            {{ moment(row.item.fact_date_time).format('MMM Do YYYY') }}
          </template>
        </b-table>
        <h3>Choose payment method</h3>
        <b-form-select
          v-model="default_payment_type"
          @change="change_payment_type"
        >
          <option
            v-for="(option, idx) in payment_types"
            :key="idx"
            :value="option.code"
            :title="option.title || null"
          >
            {{ option.title }}
          </option>
        </b-form-select>
        <h5 class="mt-1">
          Details
        </h5>
        <!-- Email -->
        <b-form-group>
          <validation-provider
            v-if="selected_payment_type.default.email !== undefined"
            #default="{ errors }"
            name="echeckEmail"
            vid="echeckEmail"
            rules="required|email"
          >
            <b-form-input
              id="echeckEmail"
              v-model="createPaymentData.email"
              :value="selected_payment_type.default.email || null"
              :state="errors.length > 0 ? false : null"
              name="echeckEmail"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Address -->
        <b-form-group
          v-if="selected_payment_type.default.address_street !== undefined"
        >
          <b-row>
            <b-col md="6">
              <label for="checkAddressStreet">Address Street</label>
              <b-form-input
                id="checkAddressStreet"
                v-model="createPaymentData.address_street"
                :value="selected_payment_type.default.address_street || null"
                name="checkAddressStreet"
              />
            </b-col>
            <b-col md="6">
              <label for="checkAddressLine">Address Line</label>
              <b-form-input
                id="checkAddressLine"
                v-model="createPaymentData.address_line"
                :value="selected_payment_type.default.address_line || null"
                name="checkAddressLine"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="4">
              <label for="checkCity">City</label>
              <b-form-input
                id="checkCity"
                v-model="createPaymentData.city"
                :value="selected_payment_type.default.city || null"
                name="checkCity"
              />
            </b-col>
            <b-col sm="4">
              <label for="checkState">State</label>
              <b-form-input
                id="checkState"
                v-model="createPaymentData.state"
                :value="selected_payment_type.default.state || null"
                name="checkState"
              />
            </b-col>
            <b-col sm="4">
              <label for="checkZipCode">Zip Code</label>
              <b-form-input
                id="checkZipCode"
                v-model="createPaymentData.zip_code"
                :value="selected_payment_type.default.zip_code || null"
                name="checkZipCode"
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-row>
          <b-col>
            <b-button
              :disabled="!selected_lessons.length"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mt-2"
              variant="success"
              block
              @click="createPaymentRequest"
            >
              Pay out ${{ selected_lessons.length * (selectedTicket ? parseInt(selectedTicket.instructor_price, 10) : null) }}
            </b-button>
          </b-col>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              class="mt-2"
              block
              @click="hideModalPayment"
            >
              Close
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-alert
              v-if="!selected_lessons.length"
              show
              variant="danger"
              class="mt-2 p-1"
            >
              Choose lessons to pay back
            </b-alert>
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BTable, BCard, BTabs, BTab, BFormDatepicker, BFormInput, BButton, BPagination, BRow, BCol, BFormCheckbox, BFormSelect, BLink,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BLink,
    BTable,
    BCardActions,
    BCard,
    BTabs,
    BTab,
    BFormDatepicker,
    BFormInput,
    BButton,
    BPagination,
    BRow,
    BCol,
    BFormCheckbox,
    BFormSelect,
    ValidationProvider,
  },
  data() {
    return {
      show: true,
      profileFile: null,
      required,
      selectedTicket: null,
      perPage: 3,
      currentPage: 1,
      modal_item: {},
      loadingLessons: true,
      tickets: [],
      lessons: [],
      selected_lessons: [],
      payment_types: [],
      default_payment_type: '',
      createPaymentData: {
        email: '',
        zip_code: '',
        address_street: '',
        address_line: '',
        city: '',
        state: '',
      },
      fields_tickets: [
        {
          key: 'number',
          label: 'Ticket Number',
          sortable: true,
        },
        {
          key: 'client_name',
          label: 'Client Name',
          sortable: true,
        },
        {
          key: 'create_date',
          label: 'Create Date',
          sortable: true,
        },
        {
          key: 'ticket_state',
          label: 'Progress',
          sortable: true,
        },
      ],
      fields_payments: [
        {
          key: 'lesson_number',
          label: 'Number of Lessons',
          sortable: true,
        },
        {
          key: 'fact_date_time',
          label: 'Actual Date',
          sortable: true,
        },
      ],
      fields_tickets_mobile: [
        {
          key: 'client_name',
          label: 'Client',
          sortable: true,
        },
        {
          key: 'create_date',
          label: 'Create Date',
          sortable: true,
        },
        {
          key: 'ticket_state',
          label: 'Progress',
          sortable: true,
        },
      ],
      fields_lessons_mobile: [
        {
          key: 'selected',
          label: '',
          sortable: false,
        },
        /* {
          key: 'plan_date_time',
          label: 'Plan Date',
          sortable: true,
        }, */
        /* {
          key: 'move_date_time',
          label: 'Move Date',
          sortable: true,
        }, */
        {
          key: 'lesson_number',
          label: 'Number',
          sortable: true,
        },
        {
          key: 'fact_date_time',
          label: 'Actual Date',
          sortable: true,
        },
        {
          key: 'bt_confirm_payment',
          label: '',
          sortable: true,
        },
      ],
    }
  },
  computed: {
    active_tickets() {
      return this.tickets.filter(t => t.is_active === '1')
    },
    past_tickets() {
      return this.tickets.filter(t => t.is_active !== '1')
    },
    rows_tickets() {
      return this.active_tickets.length
    },
    selected_payment_type() {
      let result = this.payment_types.find(t => t.code === this.default_payment_type)
      if (!result) {
        result = {
          default: {},
        }
      }
      // console.log(JSON.stringify(result))
      return result
    },
  },
  created() {
    this.$http.get('/api/lessons/tickets', {
    }).then(res => {
      // ? const data = JSON.stringify(res.data)
      // ? console.log('refresh tickets')
      // ? console.log(data)
      this.tickets = res.data
      // console.log(JSON.stringify(res.data))
      if (this.tickets.length > 0) {
        this.lessonsRefresh(this.tickets[0])
      }
    })
    this.refreshPaymentTypes()
  },
  methods: {
    isMobile() {
      if (document.documentElement.clientWidth <= 800) {
        return true
      }
      return false
    },
    onClickText() {
      // console.log('ge')
    },
    handleImage(e) {
      const inputImageRenderer = e.target.files[0]
      if (!inputImageRenderer) {
        e.preventDefault()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CompassIcon',
            variant: 'success',
            text: 'No file chosen',
          },
        })
        return
      }
      if (inputImageRenderer.size > 800 * 1024) {
        e.preventDefault()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CompassIcon',
            variant: 'danger',
            text: 'File too big (> 800KB)',
          },
        })
        return
      }

      this.createBase64Image(inputImageRenderer)
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Success',
          icon: 'CompassIcon',
          variant: 'success',
          text: 'File uploaded',
        },
      })
    },
    show_confirm_lesson_modal(lessonActual) {
      this.modal_item = { ...{}, ...lessonActual }
    },
    createPaymentRequest() {
      // console.log(JSON.stringify(this.createPaymentData))
      this.$http.post('/api/profile/create_payment_request', this.createPaymentData)
        .then(res => {
          if (res.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'CompassIcon',
                variant: 'danger',
                text: res.data.error.message,
              },
            })
          } else {
            this.hideModalPayment()
          }
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success',
              icon: 'CompassIcon',
              variant: 'success',
              text: 'Success payment request',
            },
          })
          this.lessonsRefresh(this.selectedTicket)
        })
    },
    refreshPaymentTypes() {
      this.$http.get('/api/profile/get_payment_types', {})
        .then(res => {
          this.payment_types = res.data.list
          this.default_payment_type = res.data.default_type
        })
    },
    showModalPayment() {
      this.selected_lessons = this.lessons.filter(lesson => lesson.selected === true)
      this.change_payment_type(this.default_payment_type)
      this.$refs.paymentModal.show()
    },
    hideModalPayment() {
      this.$refs.paymentModal.hide()
    },
    hideModal() {
      this.$refs.myModal.hide()
    },
    lessonsRefresh(record) {
      this.loadingLessons = true
      this.selectedTicket = record
      const query = `/api/lessons/lessons_by_ticket?ticket_id=${record.ticket_id}`
      this.$http.get(query)
        .then(res => {
          // console.log(JSON.stringify(res.data))
          this.lessons = (res.data === '' ? null : res.data) ?? []
          this.lessons.forEach(lesson => {
          // eslint-disable-next-line no-param-reassign
            lesson.selected = lesson.fact_date_time && !lesson.payment_id
          })
        })
        .catch(() => {})
        .then(() => {
          this.loadingLessons = false
        })
    },
    confirm_lesson() {
      this.$http.post('/api/profile/confirm_lesson', this.modal_item)
        .then(res => {
          if (res.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'CompassIcon',
                variant: 'danger',
                text: res.data.error.message,
              },
            })
          } else {
            this.hideModal()
          }
          this.lessonsRefresh(this.selectedTicket)
        })
    },
    createBase64Image(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const base64String = /,(.+)/.exec(reader.result)[1]
        // console.log(`file to base64 result: ${base64String}`)
        if (base64String) {
          this.modal_item.photo = base64String
        }
      }
      // reader.onerror = error => {
      // console.log('Error: ', error)
      // }
    },
    change_payment_type(value) {
      const paymentType = this.selected_payment_type

      this.createPaymentData.payment_method = value

      this.createPaymentData.details = {
        email: paymentType.default.email,
        address_street: paymentType.default.address_street,
        address_line: paymentType.default.address_line,
        city: paymentType.default.city,
        state: paymentType.default.state,
        zip_code: paymentType.default.zip_code,
      }

      this.createPaymentData.email = paymentType.default.email
      this.createPaymentData.address_street = paymentType.default.address_street
      this.createPaymentData.address_line = paymentType.default.address_line
      this.createPaymentData.city = paymentType.default.city
      this.createPaymentData.state = paymentType.default.state
      this.createPaymentData.zip_code = paymentType.default.zip_code
      this.createPaymentData.lessons = this.selected_lessons.map(lesson => (lesson.lesson_id))

      // console.log(JSON.stringify(value))
    },
  },
}
</script>
<style>
  .hide_button{
    display: none;
  }

  .show_button{
    display: block;
  }

  .slide-fade-enter-active {
  transition: all .3s ease-out;
  }

  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }
</style>
